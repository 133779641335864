import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Image from 'next/image'
import { useRouter } from 'next/router'
import WifButton from '../components/WifButton'
import SimplifiedLayout from '../layouts/SimplifiedLayout'

const NotFoundPage = () => {
  const router = useRouter()
  const { t } = useTranslation('404')

  const handleExplore = () => {
    router.push('/explore')
  }

  return (
    <SimplifiedLayout from={'/'} browserTitle={'Not Found'} title='Page not found'>
      <div
        className='flex flex-col text-center items-center max-w-[300px] mx-auto'
        data-test='404-page'
      >
        <div className='w-[140px] h-[112px] mt-[150px] mb-[50px]' data-test='404-image'>
          <Image
            src='/images/404-illustration.png'
            width={140}
            height={112}
            sizes='100vw'
            style={{ objectFit: 'fill', width: '100%', height: 'auto' }}
          />
        </div>
        <div className='font-quicksand font-bold text-base mb-[12px]' data-test='404-title'>
          {t('Page_Inactive')}
        </div>
        <div className='font-medium text-sm mb-[32px]' data-test='404-description'>
          <p>{t('Page_Inactive_Description')}</p>
        </div>
        <WifButton text={t('Explore_CTA')} handleClick={handleExplore} id='404-explore-button' />
      </div>
    </SimplifiedLayout>
  )
}

export const getStaticProps = async (ctx) => {
  const { locale, defaultLocale } = ctx
  return {
    props: {
      ...(await serverSideTranslations(locale || defaultLocale || 'id', ['404'])),
    },
  }
}

export default NotFoundPage
