import NavBar from '@/components/NavBar'
import clsx from 'clsx'
import Head from 'next/head'
import { PropsWithChildren, ReactNode } from 'react'
import AppContainer from './AppContainer'

interface SimplifiedLayoutProps {
  from: string
  browserTitle: string
  title: string
  className?: string
  withExtraLevel?: boolean
  closeButton?: boolean
  customAction?: ReactNode
  handleClose?: () => void
  extraLevelMargin?: number
}

const SimplifiedLayout: React.FC<PropsWithChildren<SimplifiedLayoutProps>> = ({
  children,
  from,
  browserTitle,
  title,
  className,
  withExtraLevel,
  closeButton = false,
  handleClose,
  customAction,
  extraLevelMargin = 0,
}) => {
  return (
    <AppContainer>
      <Head>
        <title>{browserTitle}</title>
      </Head>
      <NavBar
        simplified
        title={title}
        from={from}
        rightActions={[]}
        backButton={!closeButton}
        handleClose={handleClose}
        children={customAction}
      />
      <div
        className={clsx(className, 'pb-[70px]')}
        style={withExtraLevel ? { paddingTop: Number(extraLevelMargin) + 60 } : { paddingTop: 60 }}
      >
        <div
          className={clsx(
            withExtraLevel ? 'min-h-[calc(100vh-190px)]' : 'min-h-[calc(100vh-130px)]'
          )}
          style={
            withExtraLevel
              ? { minHeight: `calc(100vh-${(130 + Number(extraLevelMargin)).toString}px)` }
              : { minHeight: `calc(100vh-130px)` }
          }
        >
          {children}
        </div>
      </div>
    </AppContainer>
  )
}

export default SimplifiedLayout
